import PropTypes from "prop-types"
import React from "react"

const ButtonList = ({ items, parentId, selectedItemId }) => (
  <div className="button-list uk-section-xsmall">
    {(items || []).map(item => (
      <a
        key={`subcategory_button__${item._id}`}
        href={`/category/${parentId}/sub/${item._id}`}
        className={
          item._id === selectedItemId
            ? "uk-button round uk-button-primary"
            : "uk-button round outline"
        }
      >
        {item.name}
      </a>
    ))}
  </div>
)

ButtonList.propTypes = {
  parentId: PropTypes.string,
  items: PropTypes.array,
  selectedItemId: PropTypes.string,
}

//   Footer.defaultProps = {
//     siteTitle: ``,
//   }

export default ButtonList
