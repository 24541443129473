import PropTypes from "prop-types"
import React from "react"

const TitleWithCopyLink = ({ title, id, type, className, style, badge }) => {
  type = type || "h3"
  className = `title-copy ${className || "uk-text-bold uk-text-center"}`
  id = id || title?.toLowerCase().replace(/ /g, "-")

  let tooltip = "Copy link to clipboard"

  const icon = (
    <a
      href={`#${id}`}
      className="uk-icon-link copy-link-icon"
      data-uk-icon="icon:link; ratio: 1"
      data-uk-tooltip={`title: ${tooltip}; pos: right`}
      onClick={event => {
        try {
          navigator.clipboard.writeText(
            window.location.href.split("#")[0] + `#${id}`
          )
          const copiedSpan = document.createElement("span")
          copiedSpan.textContent = "Copied!"
          event.nativeEvent.target.parentElement.appendChild(copiedSpan)
          setTimeout(() => {
            event.nativeEvent.target.parentElement.removeChild(copiedSpan)
          }, 1500)
        } catch (err) {
          console.error("Failed to copy: ", err)
        }
      }}
    ></a>
  )

  const content = (
    <>
      {title}
      {badge ? (
        <span>
          {" "}
          <span className="uk-badge uk-margin-small">{badge}</span>{" "}
        </span>
      ) : (
        <></>
      )}
      {icon}
    </>
  )

  switch (type) {
    case "h2":
      return (
        <h2 id={id} className={className} style={style}>
          {content}
        </h2>
      )
    case "h3":
      return (
        <h3 id={id} className={className} style={style}>
          {content}
        </h3>
      )
    default:
      return (
        <h1 id={id} className={className} style={style}>
          {content}
        </h1>
      )
  }
}

TitleWithCopyLink.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  badge: PropTypes.number,
}

TitleWithCopyLink.defaultProps = {
  title: "",
  id: "",
  type: "h3",
  className: "",
  style: {},
  badge: null,
}

export default TitleWithCopyLink
