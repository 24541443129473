export function matchDocumentsToFiles(
  product: any,
  fileNodes: any[],
  submittalSheetNodes: any[] = []
): any[] {
  fileNodes = fileNodes || []
  submittalSheetNodes = submittalSheetNodes || []
  return (product?.documents || []).map(document => {
    if (document.file) {
      document.path = (
        (fileNodes.find(edge => edge.node.name.includes(document.file)) || {})
          .node || {}
      ).publicURL
    }
    if (!document.path && !!document.name) {
      document.path = (
        (
          fileNodes
            .concat(submittalSheetNodes)
            .find(
              edge =>
                (edge.node.name?.toLowerCase().includes("submittal") &&
                  edge.node.name === product.productSeries) ||
                (edge.node.name
                  ?.toLowerCase()
                  .includes(document.name.toLowerCase()) &&
                  edge.node.name
                    ?.toLowerCase()
                    .includes(product.productSeries.toLowerCase()))
            ) || {}
        ).node || {}
      ).publicURL
    }
    if (!document.path && document.name.toLowerCase().includes("submittal")) {
      document.path = (
        (
          submittalSheetNodes.find(
            edge =>
              edge.node.name?.toLowerCase() ===
              product.productSeries.toLowerCase()
          ) || {}
        ).node || {}
      ).publicURL
    }
    return document
  })
}
