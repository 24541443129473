import PropTypes from "prop-types"
import React from "react"

const FAQs = ({ items, productSeries }) => {
  const faqs = (items || []).filter(item =>
    !!productSeries
      ? item.productSeries.toLowerCase() === productSeries.toLowerCase()
      : !item.productSeries
  )

  return faqs.length ? (
    <div id="faqs" className="faqs-list uk-section-xsmall">
      <ul data-uk-accordion>
        {faqs.map((item, i) => (
          <li key={`faq_${i}`}>
            <a className="uk-accordion-title" href={`#faq_${i}`}>
              {item.question}
            </a>
            <div className="uk-accordion-content">
              <p>{item.answer}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <></>
  )
}

FAQs.propTypes = {
  items: PropTypes.array,
  productSeries: PropTypes.string,
}

FAQs.defaultProps = {
  items: [],
  productSeries: ``,
}

export default FAQs
